import React from "react"
import PropTypes from "prop-types"
import { useLocation } from '@reach/router';
import { Link as NavLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Link from "@mui/material/Link";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import Drawer from '@mui/material/Drawer';

function Header(props) {
  const { window, siteTitle, menuLinks } = props;
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  }

  const container = window !== undefined ? () => window().document.body : undefined;

  const menuItems = (
    menuLinks.map(link => (
      <li
        key={link.name}
        style={{
          listStyleType: `none`,
          padding: `1rem`,
        }}
      >
        <Link
              color="inherit"
              variant="button"
              underline="none"
              to={link.link}
              component={NavLink}
              activeClassName="NavLink-Selected"
            >
              {link.name}
            </Link>
      </li>
    ))
  );

  return (
    <>
      <AppBar position="fixed" elevation={trigger ? 4 : 0} color="default" sx={{backgroundColor: 'white'}}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/"
            sx={{ fontSize: {xs: 20, md: 24}, fontWeight: 300, textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}
          >
            <StaticImage
                        src="../images/logo.png"
                        placeholder="blurred"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="Radiant Family Home Logo"
                        width={40}
                        height={35}
                        style={{marginRight: '10px'}}
                    />
            {siteTitle}
          </Link>
          <Box
            component="nav"
            sx={{
              flex: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'flex-end',
              'a': {
                ml: 3,
                letterSpacing: '0.1em'
              }
            }}>
            {menuItems}
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        sx={{
          display: { md: 'none', xs: 'block' },
          width: '100%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '100%',
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        anchor="left">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleDrawerToggle}>
            <CancelIcon />
          </IconButton>
        </Box>
        {menuItems}
      </Drawer>
    </>
  )
}



Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
