import React from "react"
import PropTypes from "prop-types"
import { Link as NavLink } from 'gatsby';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"


const Footer = () => {
    const theme = useTheme();
    return (
        <Box component="footer"
            sx={{
                position: 'relative',
                marginTop: `2rem`,
                pt: 8,
                backgroundColor: '#333333',
                color: '#cccccc',
                '.MuiTypography-p': {
                    fontSize: '14px',
                    fontWeight: '300',
                    display: 'block'
                }
            }}
        >
            <Container sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', mb: 4 }}>
                <Box>
                    <Typography variant="h4" sx={{ display: 'inline-block', fontSize: { xs: '24px', md: '36px' }, fontWeight: '100', textTransform: 'uppercase', color: '#fff' }}>Let's stay&nbsp;</Typography>
                    <Typography variant="h4" sx={{ display: 'inline-block', fontSize: { xs: '24px', md: '36px' }, fontWeight: '700', textTransform: 'uppercase', color: theme.palette.primary.main }}>in touch</Typography>
                    <Typography variatn="p" sx={{ mb: 4 }}>Be the first to know what we're up to.</Typography>
                </Box>
                <Box component="form" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: 'fit-content', flex: '1 auto', justifyContent: 'flex-end' }} name="Email Sign Up" data-netlify="true" netlify-honeypot="name" action="/thank-you">
                    <input type="hidden" name="form-name" value="Email Sign Up" />
                    <TextField label="Email" type="email" color="primary" variant="filled" sx={{ backgroundColor: 'white', mr: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 } }} name="email" />
                    <TextField label="Name" type="text" color="primary" variant="filled" sx={{ backgroundColor: 'white', mr: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 } }} name="name" sx={{display: 'none'}} />
                    <Button type="submit" variant="contained" size="large">Keep me Updated!</Button>
                </Box>
            </Container>
            <Container maxWidth="lg" sx={{ mb: 4 }}>
                <Divider variant='middle' sx={{ bgcolor: 'rgba(255,255,255,.1)' }} />
            </Container>
            <Container sx={{ mb: 4 }}>
                <Grid container spacing={4}>
                    <Grid item xs md sx={{ mb: 4 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography
                                variant="h6"
                                sx={{ fontSize: { xs: 20, md: 24 }, fontWeight: 400, textTransform: 'uppercase', display: 'inline-block', alignItems: 'center', mb: 3, color: 'white' }}
                            >
                                Radiant Family Home
                            </Typography>
                        </Box>
                        <Typography variant="body1">Our mission is to help you make more informed choices when it comes to the products and practices you use around your home. We want to help you be more eco-friendly, waste less, and support green and local businesses.</Typography>
                        <Box sx={{ display: 'flex', svg: { color: theme.palette.primary.contrastText, '&:hover': { color: theme.palette.primary.main }, mr: 2 }, mt: 2 }}>
                            <Link target="_blank" href="https://www.facebook.com/Radiant-Family-Home-105171228666939"><FontAwesomeIcon icon={faFacebook} size="lg" /></Link>
                            <Link target="_blank" href="https://www.twitter.com/rfh_social"><FontAwesomeIcon icon={faTwitter} size="lg" /></Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* <Typography variant="h6" sx={{ fontSize: { xs: '18px', md: '24px' }, fontWeight: '200', textTransform: 'uppercase', color: '#fff' }}>Learn</Typography>
                        <List>
                            <ListItem button component={Link} to="/about" disablePadding><ListItemText>What we grow</ListItemText></ListItem>
                            <ListItem button component={Link} to="/about" disablePadding><ListItemText>Farming practices</ListItemText></ListItem>
                            <ListItem button component={Link} to="/about" disablePadding><ListItemText>Farm history</ListItemText></ListItem>
                        </List> */}
                    </Grid>
                    <Grid item xs={12} md={3}>

                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ mb: 4 }}>
                <Divider variant='middle' sx={{ bgcolor: 'rgba(255,255,255,.1)' }} />
            </Container>
            <Container sx={{ pb: 4 }}>
                © {new Date().getFullYear()}, Radiant Family Home
            </Container>
        </Box>
    )
}

// Footer.propTypes = {
//     children: PropTypes.node.isRequired,
// }

export default Footer
